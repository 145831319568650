<div class="modal-header">
    <h4 class="modal-title pull-left">New Nomination</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-center">
      <a class="btn btn-primary" (click)="onCreateNew()">Create New Profile</a>
    </p>
    <p class="text-center">
      <a class="btn btn-success" (click)="onUseExisting()">Use Existing Profile</a>
    </p>
  </div>