<section id="wrapper" class="login-register">
    <div class="login-box login-sidebar">
      <div class="white-box">
        <form name="loginform" class="form-horizontal form-material" id="loginform" (ngSubmit)="login()" novalidate>
          <a href="javascript:void(0)" class="text-center db"><img src="assets/img/logo.jpg" alt="Radius Direct" /></a>
          <div *ngIf="error" class="alert alert-danger m-t-40">{{error}}</div>
          <div class="form-group m-t-40">
            <div class="col-xs-12">
              <input type="text" placeholder="Username" [(ngModel)]="model.username" class="form-control"
                name="username" />
            </div>
          </div>
          <div class="form-group">
            <div class="col-xs-12">
              <input type="password" placeholder="Password" [(ngModel)]="model.password" class="form-control"
                name="password" />
            </div>
          </div>
          <div class="form-group m-b-0">
            <div class="col-sm-12">
              <div class="checkbox ">
                <input id="remember_me" name="remember_me" type="checkbox" [(ngModel)]="model.remember_me">
                <label for="remember_me">Remember Me</label>
              </div>
            </div>
          </div>
          <div class="form-group m-t-20 ">
            <div class="col-sm-12">
              <Button class="btn btn-info m-l-0" [disabled]="loading">Log in</Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>