<app-site-header [company]="company"></app-site-header>
<div class="right-icon-wrp">
  <div class="event-description">
    <div class="col-sm-12 company-list-wrp">
      <div class="white-box">
        <div class="list-eng-wrp">
          <div class="li-en-table">
            <div *ngIf="esifForm && !status">
              <app-esif-form-control [component]="component" [index]="0" [values]="esifData" [errors]="esifFormErrors"
                [adminFlags]="esifFormAdminFlags" [speaker]="speaker" [office]="office" [states]="states"
                [speakerSpecialties]="speakerSpecialties" [speakerSubSpecialties]="speakerSubSpecialties"
                *ngFor="let component of esifForm">
              </app-esif-form-control>
              <div>
                <button type="button" class="btn btn-primary"
                  (click)="onSubmit()">Submit</button>&nbsp;
                <button type="button" class="btn btn-default" (click)="saveForLater()">Save for Later
                  Submission</button>
              </div>
            </div>
            <div *ngIf="isStatus('notPendingEsif')">
              <h2 class="text-center">There are no speaker information forms awaiting your submission.</h2>
            </div>
            <div *ngIf="isStatus('thankyou')">
              <h2 class="text-center">Thank you for your interest in participating in the 2020 Salix Speakers Bureau. We
                will reach out to you if we have any questions.</h2>
            </div>
            <div *ngIf="isStatus('decline')">
              <h2 class="text-center">
                Are you sure you wish to decline?
              </h2>
              <p class="text-center">
                <button type="button" class="btn btn-primary" (click)="onDecline()">Yes</button>
                <button type="button" class="btn btn-default" (click)="onNoDecline()">No</button>
              </p>
            </div>
            <div *ngIf="isStatus('declined')">
              <h2 class="text-center">Thank you for notifying us. Your decision to decline has been recorded.</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>