<div class="right-icon-wrp">
  <div class="event-description">
    <div class="col-sm-12 company-list-wrp">
      <div class="white-box">
        <div class="list-eng-wrp">
          <div class="li-en-table">
            <p>This service is provided by eNova</p>
            <p>Access is by invite only</p>
            <p>Please use the link provided to you by email</p>
            <p>If you did not receive an email, please contact your nominating company and request a new invitation</p>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>